<template>


        <div class="row col-12" style="margin-top:-50px;margin-bottom:10px">
            <div class="col-10"></div>
            <div class="col-1" style='padding:0'>
                
                <!-- Bulk Action -->
                <div v-if="hasMultiSelect" class="dropdown" style="float:right;margin-right: -15px">
                    <button 
                        type="button" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        class="btn btn-danger dropdown-toggle width-110px"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        :style="($i18n.locale == 'ar') ? 'direction: ltr' : 'direction: rtl'"
                        :disabled="bulkLoading">

                        <span v-if="!bulkLoading"> {{ $t('app.bulkActions') }} </span>
                        <i v-if="!bulkLoading" class="mdi mdi-chevron-down"></i>

                        <span v-if="bulkLoading">
                            <span class="spinner-grow spinner-grow-sm mr-1" 
                                    role="status" 
                                    aria-hidden="true">
                            </span>
                        </span>

                    </button>


                    <div class="dropdown-menu"
                        :style="($i18n.locale == 'ar') ? 'margin-right: -50px' : ''">
                        <a href="javascript:;" 
                            @click="multiActive()"
                            class="dropdown-item"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.active') }} 
                        </a>
                        <a href="javascript:;"
                            @click="multiInactive()" 
                            class="dropdown-item"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.inactive') }}  
                        </a>
                    </div>
                </div>
                <!-- End Bulk Action -->

            </div>




            
            <div class="col-1" style='padding:0'>

                <!-- Export -->
                <div class="dropdown" 
                    :style="($i18n.locale == 'ar') ? 'float:left;margin-left:-12px' : 'float:right'">
                    <button 
                        id="dropdownMenuButton" 
                        type="button" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        class="btn btn-success dropdown-toggle width-100px"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        :style="($i18n.locale == 'ar') ? 'direction: ltr' : 'direction: ltr'"
                        :disabled="expLoading">

                        <span v-if="!expLoading">{{ $t('app.export') }}</span>
                        <i v-if="!expLoading" class="mdi mdi-chevron-down"></i>
                        <span v-if="expLoading">
                            <span 
                                class="spinner-grow spinner-grow-sm mr-1" 
                                role="status" 
                                aria-hidden="true">
                            </span>
                        </span>

                    </button>
                    <div 
                        aria-labelledby="dropdownMenuButton" 
                        class="dropdown-menu"
                        :style="($i18n.locale == 'ar') ? 'margin-right: -80px' : ''">

                        <download-excel
                            class = "dropdown-item cursor-pointer"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            :style="($i18n.locale == 'ar') ? '' : 'text-align: left'"
                            :fetch = "fetchExport"
                            :fields = "json.json_fields"
                            :before-generate = "startDownload"
                            :before-finish = "finishDownload"
                            :worksheet = "refs"
                            :name = "refs+'.xls'">{{ $t('app.excel') }}  
                        </download-excel>

                        <download-excel
                            class = "dropdown-item cursor-pointer"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            :style="($i18n.locale == 'ar') ? '' : 'text-align: left'"
                            :fetch = "fetchExport"
                            :fields = "json.json_fields"
                            :before-generate = "startDownload"
                            :before-finish = "finishDownload"
                            type = "csv"
                            :worksheet = "refs"
                            :name = "refs+'.csv'">{{ $t('app.csv') }}  
                        </download-excel>

                        <a 
                            href="javascript:;" 
                            class="dropdown-item" 
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            :style="($i18n.locale == 'ar') ? '' : 'text-align: left'"
                            v-print="'#printMe'">{{ $t('app.print') }}  
                        </a>

                        <!-- <a 
                            href="javascript:;" 
                            class="dropdown-item" 
                            @click="importExcel"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.upload_excel') }}  
                        </a>
                        <input 
                            id="file" 
                            type="file"
                            ref="file"
                            class="hidden"
                            v-on:change="handleFileUpload"> -->
                    </div>
                </div>
                <!-- End Export -->

            </div>
        </div>
        
</template>

<script>
import iziToast from 'izitoast'

export default {
    name: 'BulkAction',
    props: [
        'bulkLoading', 
        'hasMultiSelect',
        'exportLoading',
        'selected',
        'fields',
        'search',
        'period',
        'time',
        'date',
        'paid',
        'status',
        'paginate',
        'lecturer_id',
        'refs',
    ],
    data(){
        return {
            json: {
                json_fields: this.$props.fields, 
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            expLoading: this.$props.exportLoading,
            ids: this.$props.selected,

            importFile: ''
        }
    },
    mounted() {},
    watch: {
        selected: function(new_value) {
            this.ids = new_value
        },
        exportLoading: function(new_value) {
            this.expLoading = new_value
        }
    },
    created() {},
    methods: {

        async fetchExport(){
            const res = 
                await this.axios.post(window.baseURL+'/'+this.refs+'/export?locale='+this.$i18n.locale+'&search='+this.$props.search+'&period='+this.$props.period+'&time='+this.$props.time+'&date='+this.$props.date+'&paid='+this.$props.paid+'&status='+this.$props.status+'&paginate='+this.$props.paginate+'&lecturer_id=' + this.$props.lecturer_id);
            return res.data.rows;
        },
        startDownload(){
            this.expLoading = true;
        },
        
        finishDownload(){
            this.expLoading = false;
            iziToast.success({
                icon: 'bx bx-wink-smile',
                title: '',
                message: 'تم إستخراج الملف بنجاح',
            });
        },


        multiActive(event){
            this.$emit('activeChange', event)
        },

        multiInactive(event){
            this.$emit('inactiveChange', event)
        },


        importExcel() {
            this.$refs.file.click();
        },

        onFileChange(e) {
            const file = e.target.files[0];
            this.createBase64File(file);
        },

        createBase64File(fileObject){
            const reader = new FileReader();
            reader.readAsDataURL(fileObject);
            reader.onload = e =>{
                this.row.importFile = e.target.result;
                this.importData();
            };
        },

        handleFileUpload(e) {
            this.importFile = e.target.files[0];
            this.importData();
        },


        importData() {
            this.expLoading = true;

            let formData = new FormData();
            formData.append('file', this.importFile);
            
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                //'Authorization': `Bearer ` + this.auth.access_token,
            };
            const headers = {
                'Content-Type': 'multipart/form-data'
            }
            const options = {
                url: window.baseURL+'/'+this.$props.refs+'/import/excel',
                method: 'POST',
                data: formData
            }
            this.axios(options, headers)
            .then(() => {
                this.expLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: 'تم الرفع بنجاح',
                });
                this.$emit('refreshTable', '')
            })
            .catch(err => {
                this.expLoading = false;
                iziToast.warning({
                    icon: 'bx bx-angry',
                    title: '',
                    message: (err.response) ? err.response.data.message : ''+err
                });
            })
            .finally(() => {}); 
        },
        
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.width-100px {
    width: 100px;
}
.width-110px {
    width: 110px
}
</style>
